/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Desi Karunia Sari
==============================================================================================*/
@import "sass-lib.scss";
@media (min-width: 0px) and (max-width: 1680px) {
	.wrapper-large {
		width: 1400px;
		margin: 0 auto;
	}
	header {
		.wrapper-large {
			.logo {
				margin-top: 0;
				img {
					width: 250px;
				}
			}
			ul.menu {
				li {
					a {
						padding-left: 13px;
						padding-right: 13px;
					}

					&.dropdown {
						> a {
							padding-right: 34px;

							&::after {
								content: "";
								position: absolute;
								top: 0;
								bottom: 0;
								right: 13px;
								width: 15px;
								height: 7px;
								margin: auto;
								background: url("../images/material/down-fff.png") no-repeat
									center center;
								background-size: contain;
							}
						}
					}
				}
			}
		}
		&.active {
			.wrapper-large {
				.logo {
					margin-top: 0;
					margin-left: 35px;
				}
				ul {
					&.menu {
						margin-right: 60px;
					}

					li {
						&.dropdown {
							> a {
								&::after {
									background: url("../images/material/down-disabled.png")
										no-repeat center center;
									background-size: contain;
								}

								&.active,
								&:hover {
									&::after {
										background: url("../images/material/down-fff.png") no-repeat
											center center;
										background-size: contain;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.landing {
		.box {
			.index-img {
				height: 768px;
				.img-landing {
					height: 100vh;
				}
				.desc-landing {
					padding: 35px 25px;
					width: 30.4vw;
					.title {
						font-size: 33px;
					}
				}
			}
		}
		.highlight-text {
			h1 {
				font-size: 33px;
			}
		}
	}
	.latest-news {
		.box-latest-news {
			.box-news-title {
				padding: 80px 0px 80px 30px;
				h2 {
					font-size: 40px;
				}
			}
			.box-news {
				width: 290px;
				margin: 80px 0;
				.box-title {
					font-size: 23px;
				}
			}
		}
	}
	.homepage {
		.main-slider {
			.content {
				h1 {
					font-size: 48px;
				}
			}
			.slick-dots {
				li {
					button {
						left: 1530px;
					}
				}
			}
		}
	}
	.text-dirext {
		font-size: 16px;
	}
	.about-us {
		.content {
			.img-content {
				margin-right: 0px;
				img {
					width: 500px;
				}
			}
			.desc-content {
				margin-left: 280px;
				.highlight-text {
					font-size: 33px;
				}
				.logo-content {
					margin-top: -50px;
				}
			}
		}
	}
	.investing {
		.content {
			.img-content {
				margin-right: 0px;
				img {
					width: 500px;
				}
			}
			.desc-content {
				margin-left: 35px;
				.highlight-text {
					font-size: 33px;
				}
				.logo-content {
					margin-top: -50px;
				}
			}
		}
	}

	.menu-ui {
		ul {
			li {
				&.dropdown {
					> a {
						padding-right: 34px;

						&::after {
							content: "";
							position: absolute;
							top: 0;
							bottom: 0;
							right: 13px;
							width: 15px;
							height: 7px;
							margin: auto;
							background: url("../images/material/down-disabled.png") no-repeat
								center center;
						}
					}
				}
			}
		}
	}

	.mid-content {
		.middle {
			.box-content {
				width: 33.33%;
				h3 {
					font-size: 33px;
				}
			}
		}
	}
	.pagingInfo {
		right: 290px;
	}
	.video-project {
		.video-slider {
			.item {
				.cover-thumb {
					img {
						margin: auto;
						width: 80px;
					}
				}
			}
		}
	}
	.slick-slide img {
		max-width: 100%;
	}
	footer {
		.menu-footer {
			margin: 0;
		}
	}
}

@media (min-width: 0px) and (max-width: 1440px) {
	.wrapper-large {
		width: 1300px;
		margin: 0 auto;
	}
	header {
		.wrapper-large {
			padding: 30px 80px 20px 0px;
			.fr .top {
				margin-right: -75px;
			}
			.top a.announcement {
				font-size: 13px;
				font-weight: bold;
			}
			.top .lang a {
				font-size: 13px;
			}
			.menu {
				margin-right: -70px;
			}
			.logo {
				margin-top: 0;
				img {
					width: 250px;
				}
			}
			ul {
				&.menu {
					li {
						a {
							font-size: 13px;
						}
					}
				}
			}
		}
		&.active {
			.wrapper-large {
				padding-bottom: 20px;
				.fr .top {
					margin-right: 0px;
				}
				.top a.announcement {
					font-size: 13px;
					font-weight: bold;
				}
				.logo {
					margin-left: 15px;
				}
				ul {
					&.menu {
						margin-right: 15px;
						margin-top: -10px;
						li {
							a {
								font-size: 13px;
							}
						}
					}
				}
			}
		}
	}
	.landing {
		.box {
			.index-img {
				height: 100vh;
				.img-landing {
					.bg {
						height: 100vh;
					}
				}
			}
		}
	}
	.box-video .slick-arrow.nav-left {
		right: 300px;
		bottom: 375px;
	}
	.box-video .slick-arrow.nav-right {
		right: 250px;
		bottom: 375px;
	}
	.homepage {
		.main-slider {
			.content {
				padding: 40px 30px;
			}
			h1 {
				font-size: 40px;
			}
			.list {
				height: auto;
			}
			.slick-dots {
				li {
					button {
						left: 1200px;
						top: -65px;
					}
				}
			}
		}
	}
	.wrapper-large.about-us {
		position: relative;
		top: -9px;
	}
	.wrapper-large.investing {
		position: relative;
		top: 0;
	}
	.about-us {
		padding: 150px 30px;
		.content {
			.img-content {
				width: 30%;
				margin-left: -27%;
				img {
					width: 457px;
				}
			}
			.desc-content {
				margin-left: 130px;
				.highlight-text {
					font-size: 33px;
					line-height: 40px;
				}
			}
		}
	}
	.investing {
		padding: 150px 30px;
		.content {
			.img-content {
				width: 30%;
				margin-left: -27%;
				img {
					width: 457px;
				}
			}
			.desc-content {
				margin-left: 85px;
				.highlight-text {
					font-size: 33px;
					line-height: 40px;
				}
			}
		}
	}
	.video-project {
		.video-slider {
			.item {
				.desc-project {
					width: 1000px;
					.info-text {
						h2 {
							font-size: 40px;
						}
					}
					.description {
						p {
							margin-bottom: 10px;
						}
					}
				}
			}
		}
	}
	.mid-content {
		.middle {
			.info {
				h2 {
					font-size: 33px;
					line-height: 40px;
					width: 90%;
				}
			}
			.highlight-text {
				font-size: 33px;
				line-height: 40px;
			}
			.content-left,
			.content-right {
				.title {
					font-size: 33px;
				}
			}
			.content-left {
				.image-cover {
					max-height: 300px;
					img {
						/*width: 90%;*/
						margin-bottom: 50px;
					}
				}
			}
			.content-profile {
				.content-left {
					.image-cover {
						max-height: unset;
					}
				}
			}
			.list-profile {
				.profile {
					width: 285px;
				}
			}
		}
		&.visionmission {
			padding: 60px 150px;
		}
	}
	.menu-id {
		ul {
			li {
				a {
					font-size: 13px;
				}
			}
		}
	}
	.csr-slider {
		margin-bottom: 130px;
		.list {
			img {
				width: 100%;
				border-radius: 5px;
			}
		}
	}
	.pagingInfo {
		right: 135px;
	}
	.homepage-project {
		p {
			font-weight: lighter;
			color: #999;
		}
		a {
			text-align: right;
		}
		.box-40 {
			width: 40% !important;
		}
		.box-20 {
			width: 20% !important;
		}
		.middle {
			.box-content {
				width: 100%;
				p {
					padding: 0;
				}
				.text-direct {
					margin: 0;
				}
			}
		}
	}
	.mid-content .middle .news {
		width: 21vw;
	}
	.modal .modal-content {
		top: 18%;
		left: 50%;
	}
	.modal video {
		height: 500px;
	}
	#ytplayer {
		height: 477px;
		width: 100%;
		position: relative;
		left: 0;
	}
	.box-video.details .video-project .vid_web iframe {
		width: 100%;
		height: 100%;
	}
	footer {
		.info {
			margin-left: 0;
			width: 40%;
			.address {
				margin-left: 50px;
				width: calc(100% - 116px - 50px);
			}
		}
		.menu-footer {
			margin-right: 25px;
			width: calc(60% - 25px);
			ul {
				li {
					padding-left: 30px;
					a {
						font-size: 13px;
					}
				}
			}
		}
	}
}

@media (min-width: 0px) and (max-width: 1366px) {
	.box-video .slick-arrow.nav-left {
		bottom: 370px;
	}
	.box-video .slick-arrow.nav-right {
		bottom: 370px;
	}
}
@media (min-width: 0px) and (max-width: 1349px) {
	header {
		.wrapper-large {
			padding: 30px 80px 15px 20px;
			width: auto;
		}
	}
}
@media (min-width: 1440px) and (max-width: 1600px) {
	header {
		.wrapper-large {
			.top {
				margin-right: 0px;
			}
		}
		&.active {
			.wrapper-large {
				.top {
					margin-right: 40px;
				}
			}
		}
	}
}

@media (max-width: 1024px) {
	header {
		.wrapper-large {
			.fr {
				.top {
					display: flex;
				}
				ul.menu {
					li {
						&.announcement-menu {
							display: none;
						}
						&.search-btn {
							display: none;
						}
						&.lang {
							display: none;
						}
					}
				}
			}
		}
	}
}
@media (max-width: 768px) {
	header {
		.wrapper-large {
			.fr {
				.top {
					display: none;
				}
				ul.menu {
					li {
						&.announcement-menu {
							display: block;
						}
						&.search-btn {
							display: block;
						}
						&.lang {
							display: block;
						}
					}
				}
			}
		}
	}
}
